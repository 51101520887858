<script setup lang="ts"></script>

<template>
  <div :class="[$style.gm6]">
    <slot />
  </div>
</template>

<style module lang="scss">
.gm6 {
  width: 100%;
  height: 100vh;
}
</style>
